import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
// import { BiPhoneCall } from 'react-icons/bi'
// import { BsCalendar4Range, BsClockHistory } from 'react-icons/bs'
import Navmenu from './NavMenu'




const HeaderTwo = ({ btnName }) => {

    const { rpdata } = useContext(GlobalDataContext)
    const fondoLogo = 'https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/fondo-logo-jcbeauty.png?alt=media&token=be5f5f3e-fba8-4c7c-9958-150989fb170b&_gl=1*1yykg9u*_ga*MTg3NzIxNTgxMi4xNjczOTA2Mjk5*_ga_CW55HF8NVT*MTY5ODc5Mjk4Ny4zMDQuMS4xNjk4NzkyOTk1LjUyLjAuMA..'
    return (
        <header className='z-[15] w-full flex justify-center backgroundhearonin relative'>
            <div className='w-4/5 z-30'>
                <div className='flex w-full justify-between items-center'>
                    <div className='md:flex hidden'>
                        <ButtonContent btnStyle='four' btnLink={'contact'} />
                    </div>


                    <div className='w-[250px] mx-auto md:w-[280px] md:h-[280px] rounded-full mt-1 bg-cover flex items-center justify-center'
                    style={{backgroundImage: `url(${fondoLogo})`}}>
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            loading='lazy'
                            className='w-[100%] mx-auto'
                        />
                    </div>


                    <div className='hidden md:flex w-[18%] justify-center items-center '>
                        <ul className="flex justify-center space-x-7">
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="textColor1">
                                        <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                            <i
                                                className={`text-white fab fa-${item.icon}`}
                                                aria-hidden="true"
                                            />
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <div className='px-3 py-4'>
                    <div className='flex justify-between md:justify-center items-center border-b-pink-500 border-b-2 pb-2 mb-2'>
                        <nav>
                            <Navmenu />
                        </nav>
                        <div className='block md:hidden'>
                            <ButtonContent btnStyle='four'/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderTwo